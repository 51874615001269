@import '../../../../../assets/scss/mixins.scss';
@import '../../../../../assets/scss/variables.scss';

.uploadBox {
  width: 100%;
  background: #f7f7f7;
  border-radius: 4px;
  padding: 0.75rem;

  @include screen(xxs) {
    max-width: 341px;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.75rem;
  }

  .main {
    display: flex;
    gap: 0.75rem;

    .iconSection {
      overflow: hidden;
      position: relative;
      max-width: 136px;
      max-height: 136px;
      background: white;
      border: 1px dashed #dadadf;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      min-height: 136px;
      gap: 8px;

      .input {
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;
      }

      .label {
        font-size: 12px;
        text-align: center;
        color: #666666;
      }
    }

    .iconSectionPadding {
      padding: 0.5rem;
    }

    .remove {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .removeBtn {
        cursor: pointer;
        margin-left: auto;
      }
    }

    .iconBlue {
      svg {
        path {
          fill: $primary-color;
        }
      }
    }

    .imgInfo {
      width: 100%;
      height: 100%;
      align-content: flex-end;

      .info {
        margin-bottom: 0.25rem;
        color: #65708b;
        font-size: 14px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 0.5rem;

        .name {
          flex: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          max-width: 163px;
        }
      }
    }

    .customProgress {
      width: 100%;
      height: 6px;
      background-color: #dadadf;
      border-radius: 10px;
      overflow: hidden;
    }

    .customProgressBar {
      height: 100%;
      background-color: #199b45;
      width: 0;
      border-radius: 10px;
      transition: width 0.3s ease;

      &.blue {
        background-color: $primary-color;
      }
    }

    .progressLabel {
      margin-top: 0.25rem;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #919aad;
      font-size: 12px;
    }
  }
}
