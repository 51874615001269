.progress-container {
  width: 100%;
  padding: 20px;
  background-color: #f8f8f8;
  border-radius: 4px;
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  position: relative;
  box-sizing: border-box;
  max-height: 88vh;

  .proposta {
    font-size: 16px;
  }

  .imovel {
    text-align: end;
  }

  .bold {
    font-weight: 600;
  }

  .progress-bar {
    height: 6px;
    background-color: #e0e0e0;
    border-radius: 10px;
    overflow: hidden;

    .progress {
      height: 100%;
      background-color: #4caf50;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px 10px;

      .porcentagem {
        position: absolute;
        top: 28px;
        background-color: #013542;
        color: white;
        padding: 5px 10px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        gap: 2px;
        font-weight: 700;

        svg {
          width: 12px;
          height: 12px;
        }

        &::after {
          content: '';
          position: absolute;
          bottom: -9px;
          left: 50%;
          transform: translateX(-50%);
          width: 0;
          height: 0;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          border-top: 10px solid #013542;
        }

        &.bottom {
          bottom: 28px;

          &::after {
            top: -9px;
            border-bottom: 10px solid #013542;
          }
        }
      }

      .porcentagemBottom {
        position: absolute;
        bottom: 8px;
        background-color: #013542;
        color: white;
        padding: 5px 10px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        gap: 2px;
        font-weight: 700;

        svg {
          width: 12px;
          height: 12px;
        }

        &::after {
          content: '';
          position: absolute;
          top: -9px;
          left: 50%;
          transform: translateX(-50%);
          width: 0;
          height: 0;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          border-bottom: 10px solid #013542;
        }
      }
    }
  }
}
