.modalContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  max-width: 772px;
  padding: 48px 24px;

  &.min {
    max-width: 544px;
    border-radius: 16px;
  }

  .padlock {
    width: 80px;
    height: 80px;
    background: #f2f2f2;
    border-radius: 50%;
    padding: 16px;
    display: flex;
    justify-content: center;
    align-items: center;

    > svg {
      width: 48px;
      height: 48px;

      path {
        fill: #199b45;
      }
    }

    &.resaleFast {
      > svg {
        path {
          fill: #23365b;
        }
      }
    }
  }

  .title {
    margin-block: 16px;
    color: #013542;
    font-size: 24px;
    text-align: center;
  }

  .notice {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 8px;

    b {
      padding-inline: 0px;
      padding-bottom: 16px;
    }
  }

  .termInput {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 36px;
    align-self: flex-start;

    > input {
      width: 18px;
      height: 18px;
      accent-color: #199b45;
    }

    &.resaleFast {
      > input {
        accent-color: #7397ff;
      }
    }
  }

  .divider {
    width: 99%;
    border: 1px solid transparent;
    border-top-color: #dadadf;
    padding-bottom: 16px;
  }

  .cta {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    > button {
      min-width: 160px;
    }
  }

  .description {
    font-weight: 400;
    font-size: 16px;
    text-align: center;
  }

  > button {
    width: 228px;
    margin-top: 40px;
  }
}
