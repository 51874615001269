.container {
  width: 100%;
  display: flex;
  flex-direction: column;

  .errorContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 4px;

    .error {
      display: flex;
      margin-top: 2px;

      > p {
        font-size: 0.75rem;
        color: #fb4e5f;
      }
    }
  }

  .label {
    width: 100%;
    display: flex;
    margin-bottom: 5px;

    > p {
      font-size: 12px;
      color: #6b6b6b;
    }
  }

  .input {
    width: 100%;

    display: flex;
    align-items: center;

    ul {
      max-height: 120px !important;
    }

    $border-color: #dadade;

    &.bordered {
      $border-color: #8a8a8a;
    }

    > div {
      button {
        height: 40px !important;
        border: 1px solid $border-color !important;
        border-radius: 4px !important;
        border-bottom-right-radius: 0 !important;
        border-top-right-radius: 0 !important;
      }

      ul {
        outline: none;
      }
    }

    [class*='flag-dropdown'] {
      border-color: $border-color !important;
      background-color: #ffffff;
    }

    > input {
      flex: 1;
      height: 40px !important;
      border: 1px solid $border-color !important;
      border-radius: 4px !important;
      border-bottom-left-radius: 0 !important;
      border-top-left-radius: 0 !important;
      font-size: 1rem !important;
    }

    &.error {
      > input {
        border-color: #fb4e5f !important;

        outline: none !important;
      }

      > div {
        button {
          border-color: #fb4e5f !important;

          outline: none !important;
        }
      }

      [class*='flag-dropdown'] {
        border-color: #fb4e5f !important;
      }
    }

    &:not(.error) {
      > input {
        &:focus {
          border-color: $border-color !important;
        }

        &:focus-visible {
          border-color: $border-color !important;
        }
      }

      > div {
        button {
          border: 1px solid $border-color !important;
        }
      }

      [class*='flag-dropdown'] {
        border-color: $border-color !important;
      }
    }

    &.disabled {
      input {
        background-color: #f5f5f5;
      }

      div {
        background-color: #f5f5f5;
      }
    }
  }
}
