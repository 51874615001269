//Basic colors
$white-color: #ffffff;
$black-color: #000000;

//Primary, secondary and tertiary colors
$primary-color: #7896ff;
$secondary-color: #262626;
$tertiary-color: #fb4e5f;

//Primary, secondary and tertiary colors variations
$light-primary-color: #a0b6ff;
$dark-primary-color: #6a81d4;
$darker-primary-color: #5669ac;

//Blue colors
$blue0: #215297;
$blue1: #23355a;
$blue2: #101a33;
$lavender0: #e9ebee;
$lavender1: #65708b;
$lavender2: #828296;

//Gray colors
$gray0: #efefef;
$gray1: #e7ebf4;
$gray2: #787886;
$gray3: #c4c4c4;
$gray4: #919aad;
$gray5-translucid: #f8f8f8a6;
$gray6-translucid: rgba(255, 255, 255, 0.4);
$gray7: #222;
$gray8: #f3f3f4;
$gray9: #2b364d;
$gray10: #666666;
$gray11: #f9f9f9;

// Green colors
$fast-proposal: #199b45;

//Texts colors
$text-color: #454545;
$text-color-2: #545460;

//Background colors
$background-color: rgb(249, 249, 249);
$light-gray-background: #f0f0f0;
$asterisk-background-color: #f8f8f8;

//Others colors
$green-color: #00c8be;
$whatsapp-green-color: #48c858;
$line-color: #dadade;
$orange-color: #fa8f00;
$yellow-color: #f8d117;

//Font sizes
$font-size-10: 10px;
$font-size-12: 12px;
$font-size-14: 14px;
$font-size-16: 16px;
$font-size-18: 18px;
$font-size-20: 20px;
$font-size-24: 24px;
$font-size-26: 26px;
$font-size-28: 28px;
$font-size-30: 30px;
$font-size-32: 32px;
$font-size-36: 36px;
$font-size-35: 35px;
$font-size-36: 36px;
$font-size-40: 40px;
$font-size-60: 60px;

//Font families
$font-family: 'Titillium Web', -apple-system, BlinkMacSystemFont, 'Segoe UI',
  Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
  'Segoe UI Symbol';
$font-family-open-sans: 'Open Sans';

//Others
$notification-box-rosie-size: 85px;
$list-card-size: 445px;
