@import '../../../assets/scss/variables.scss';
@import '../../../assets/scss/mixins.scss';

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 36px 24px;
  min-height: 75vh;
  max-height: 85vh;
  max-width: 772px;

  &.min {
    max-width: 544px;
    border-radius: 16px;
    min-height: auto;
    padding: 48px 24px;
  }

  .fullInputPhone {
    width: 100%;
    display: flex;
    gap: 8px;

    > div {
      flex: 1;
    }

    > label {
      max-width: 32%;
    }
  }

  > div {
    width: 100%;
  }

  h1 {
    color: #013542;
    font-size: 24px;
    text-align: center;
    margin-bottom: 36px;
  }

  .form {
    width: 100%;
    display: flex;
    flex-direction: column;

    > div {
      display: flex;
      align-items: center;
      gap: 24px;
    }

    .radioInput {
      display: flex;
      align-items: center;
      gap: 8px;

      > input {
        width: 18px;
        height: 18px;
        accent-color: #199b45;
      }

      &.resaleFast {
        input {
          accent-color: $tertiary-color;
        }
      }
    }

    > hr {
      border: 1px solid transparent;
      border-top-color: #8a8a8a;
      margin-block: 16px;
    }
  }

  .formGrid {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 16px;

    @include screen(xs) {
      grid-template-columns: 1fr;
    }
  }

  .marginTop {
    margin-top: 16px;
  }

  .input {
    input {
      height: 40px;
      border-radius: 4px;
      border-color: #8a8a8a;
      color: #262626;
    }

    &.disabled {
      input {
        color: #6b6b6b;
        border: 1px solid #dadada;
        background: #f5f5f5;
      }
    }
  }

  .inputLabel {
    > p {
      font-size: 12px;
      color: #6b6b6b;
    }
  }

  .cep {
    width: 100%;
    position: relative;

    .loading {
      position: absolute;
      top: 24%;
      right: 84%;
      transform: translateY(-50%);
    }
  }

  .selectContainer {
    display: flex;
    flex-direction: column;

    .error {
      border-color: red;
    }

    > p {
      font-size: 12px;
      color: #6b6b6b;
      margin-block: 5px;
    }

    .select {
      height: 40px;
      border-radius: 4px;
      border: 1px solid #8a8a8a;
      outline: none;
      padding-inline: 4px;
    }
  }

  .addressContainer {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 12px;
    margin-top: 16px;

    .threeQuarters {
      grid-column: span 3;
    }

    .quarterWidth {
      grid-column: span 1;
    }
  }

  .modalFooter {
    width: 100%;
    display: flex;
    flex-direction: column;

    > hr {
      width: 100%;
      border: 1px solid transparent;
      border-top-color: #dadadf;
      margin-bottom: 16px;
    }

    .ctas {
      align-self: flex-end;
      display: flex;
      align-items: center;
      gap: 36px;

      button {
        &[name='voltar'] {
          all: unset;
          cursor: pointer;
          text-decoration: underline;
        }

        &[name='continuar'] {
          min-width: 160px;
        }
      }
    }
  }

  .uploadDocumentContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-block: 36px;
    justify-content: center;
    align-items: center;

    .securityInfo {
      width: 100%;
      background-color: #f8f8f8;
      border-radius: 8px;
      padding: 16px 24px;
      display: flex;
      flex-direction: column;
      gap: 16px;
      max-width: 724px;
      justify-content: center;
      align-items: center;
      margin-bottom: 32px;

      b {
        padding: none;
      }

      .side {
        display: flex;
        gap: 0.25rem;

        &:last-child {
          padding-right: 28px;
        }

        &.iconRed {
          svg {
            path {
              fill: $primary-color;
            }
          }
        }
      }

      .divider {
        border: 1px solid transparent;
        border-left-color: #d9d9d9;
        display: none;
      }

      @include screen(min-sm) {
        gap: 0;
        flex-direction: row;
        justify-content: space-between;

        .divider {
          height: 48px;
          display: block;
          margin-inline: 16px;
        }
      }
    }

    .uploadInputs {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 16px;

      @include screen(max-sm) {
        flex-direction: column;
      }
    }
  }

  .conditionsContainer {
    display: flex;
    flex-direction: column;

    .info {
      margin-block: 24px;
    }

    .bold {
      font-weight: 700;
      margin-bottom: 8px;
    }

    .list {
      display: flex;
      flex-direction: column;
      gap: 4px;
      margin-left: 8px;

      .line {
        display: flex;

        .input {
          cursor: pointer;

          input {
            cursor: pointer;
            width: 15px;
            height: 22px;
            margin-left: 8px;
            margin-right: 8px;
            margin-block: 0;
            accent-color: #199b45;
          }

          &.red {
            input {
              accent-color: $primary-color;
            }
          }
        }
        a {
          all: unset;
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }
  }

  .finalContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;

    .padlock {
      width: 80px;
      height: 80px;
      background: #f2f2f2;
      border-radius: 50%;
      padding: 16px;
      display: flex;
      justify-content: center;
      align-items: center;

      > svg {
        width: 48px;
        height: 48px;

        path {
          fill: #199b45;
        }
      }
    }

    .title {
      margin-block: 16px;
      color: #013542;
      font-size: 24px;
      text-align: center;
    }

    .description {
      font-weight: 400;
      font-size: 16px;
      text-align: center;
    }

    .closeBtn {
      height: 52px;
      width: 276px;
      margin-top: 40px;
    }
  }
}
