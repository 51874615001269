@import '../../../assets/scss/variables.scss';
@import '../../../assets/scss/mixins.scss';

.container {
  max-width: 772px;
  padding: 36px 24px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &.min {
    max-width: 544px;
  }

  .icon {
    width: 80px;
    height: 80px;
    background: #f2f2f2;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    > svg {
      width: 48px;
      height: 48px;
    }

    &.redColor {
      svg {
        path {
          fill: $tertiary-color;
        }
      }
    }
  }

  .propostometro {
    background: #fff;
    border: 1px solid #dadadf;
    border-radius: 8px;
    padding: 16px;
    box-shadow: 0px 0px 12px 1px #00000040;
    position: relative;
    max-width: 300px;
    align-self: flex-end;
    box-sizing: border-box;

    &::before {
      content: '';
      position: absolute;
      top: 44%;
      left: -9px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 10px 10px 10px 0;
      border-color: transparent #fff transparent transparent;
      filter: drop-shadow(-2px 2px 4px rgba(0, 0, 0, 0.15));
    }

    @media (max-width: 800px) {
      &::before {
        content: none;
      }
    }

    .propostometroHeader {
      display: flex;
      align-items: center;
      gap: 8px;
      margin-bottom: 8px;

      .label {
        color: #ff3250;
      }
    }

    .loadingPropostometro {
      width: 100%;
      height: 128px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .label {
      font-weight: 700;
      color: #262626;
      line-height: 26px;
      font-size: 14px;
      margin-bottom: 8px;
    }

    .labelRed {
      color: #ff3250;
    }

    .text {
      font-weight: 400;
      font-size: 12px;
      margin-bottom: 8px;
    }

    .progressLabel {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 4px;
      color: #666666;
      font-size: 10px;
    }

    .progress {
      border-radius: 4px;
      width: 100%;
      height: 16px;
      background: linear-gradient(90deg, #ff4704 0%, #d9b100 50%, #00d604 100%);
      position: relative;
    }

    .marker {
      height: 24px;
      width: 4px;
      max-width: 4px;
      position: absolute;
      background: #000000;
      border-radius: 2px;
      top: 50%;
      transform: translateY(-50%);
    }

    .asterisk {
      font-size: 10px;
      font-weight: 400;
      padding-top: 8px;
    }
  }

  .title {
    font-size: 24px;
    font-weight: 700;
    color: #262626;
    padding-block: 16px 24px;
  }

  .description {
    color: #262626;
    text-align: center;
  }

  .cta {
    height: 52px;
    width: 100%;
    max-width: 276px;
    margin-top: 40px;
  }
  .valueForm {
    width: 100%;
    display: flex;
    flex-direction: column;

    .partnerCode {
      position: relative;

      .loading {
        position: absolute;
        left: 138px;
        top: 6px;
      }
    }

    .confirmValueMsg {
      margin: 5px;
      font-size: 12px;
      color: #6b6b6b;
    }

    .inputLabel {
      > p {
        font-size: 12px;
        color: #6b6b6b;
      }

      &.marginTop {
        > p {
          margin: 12px 0 5px 0;
        }
      }

      > textarea {
        width: 100%;
        max-width: 318px;
        outline: none;
        resize: none;
        color: #262626;
        border-radius: 4px;
        border: 1px solid #8a8a8a;
        padding: 11px;
      }

      .error {
        border-color: $tertiary-color;
      }
    }

    .line {
      display: flex;
      flex-direction: column;
      position: relative;

      .titleLine {
        font-weight: 600;
        font-size: 16px;
      }

      .doubleLine {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 24px;
        flex-wrap: wrap;

        label,
        div {
          width: 100%;
          max-width: 342px;
        }
      }

      .input {
        max-width: 342px;

        input {
          color: #262626;
          border-radius: 4px;
          height: 40px;
          border-color: #8a8a8a;
        }

        &.disabled {
          input {
            color: #6b6b6b;
            border: 1px solid #dadada;
            background: #f5f5f5;
          }
        }
      }

      .select {
        height: 40px;
        width: 100%;
        max-width: 342px;
        outline: none;
        border-radius: 4px;
        padding-left: 4px;

        &.empty {
          color: #6b6b6b;
        }
      }
    }

    hr {
      width: 100%;
      border: 1px solid transparent;
      border-top-color: #dadadf;
      margin-block: 24px;
    }
  }

  .stepOneFooter {
    width: 100%;
    border-top: 1px solid #dadadf;
    margin-top: 36px;
    padding-top: 24px;
    display: flex;
    justify-content: flex-end;
    gap: 36px;

    button {
      &[name='voltar'] {
        all: unset;
        cursor: pointer;
        text-decoration: underline;
      }

      &[name='continuar'] {
        min-width: 160px;
      }
    }
  }

  .termsList {
    display: flex;
    flex-direction: column;
    gap: 8px;

    @include screen(xs) {
      max-height: 58vh;
      overflow-y: auto;
    }

    .line {
      display: flex;
      gap: 8px;

      a {
        all: unset;
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }

  .termsCheckbox {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 12px;
    align-self: flex-start;
    margin-left: 8px;

    input {
      width: 18px;
      height: 18px;
      accent-color: #199b45;
    }
  }

  .finalCtas {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
    margin-top: 24px;

    button {
      min-width: 160px;
    }
  }

  .propertyInfos {
    width: 100%;
    .bold {
      font-weight: 600;
    }

    .marginTop {
      margin-top: 8px;
    }
  }

  .warning {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 12px;
    font-size: 14px;
  }
}
