.modalContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  max-width: 772px;
  padding: 36px 24px;

  &.min {
    max-width: 544px;
  }

  .padlock {
    width: 80px;
    height: 80px;
    background: #f2f2f2;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    > svg {
      width: 48px;
      height: 48px;

      path {
        fill: #199b45;
      }
    }

    &.redColor {
      > svg {
        width: 48px;
        height: 48px;

        path {
          fill: #101a33;
        }
      }
    }
  }

  .title {
    margin-block: 16px;
    color: #013542;
    font-size: 24px;
    text-align: center;
  }

  .notice {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 8px;

    b {
      padding-inline: 0px;
      padding-bottom: 16px;
    }
  }

  .divider {
    width: 99%;
    border: 1px solid transparent;
    border-top-color: #dadadf;
    padding-bottom: 16px;
  }

  .cta {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    > button {
      min-width: 160px;
    }
  }

  .description {
    font-weight: 400;
    font-size: 16px;
    text-align: center;
  }

  .initBtn {
    height: 52px;
    width: 276px;
    margin-top: 40px;
  }
}
