@import '../../../assets/scss/variables.scss';
@import '../../../assets/scss/mixins.scss';

.containerModal {
  max-width: 468px;
  border-radius: 8px;
  min-height: 42vh;

  .main {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 36px;

    @include screen(xs) {
      padding-inline: 16px;
    }

    .fullInputPhone {
      width: 100%;
      display: flex;
      gap: 8px;

      > div {
        flex: 1;
      }

      > label {
        max-width: 28%;
      }

      .selectContainer {
        display: flex;
        flex-direction: column;

        .error {
          border-color: red;
        }

        > p {
          font-size: 12px;
          color: #6b6b6b;
          margin-block: 5px;
        }

        .select {
          height: 40px;
          border-radius: 4px;
          border: 1px solid #8a8a8a;
          outline: none;
          padding-inline: 4px;
        }
      }
    }

    .title {
      color: #013542;
      font-size: 24px;
      margin-bottom: 16px;
      text-align: center;
    }

    .subtitle {
      margin-bottom: 24px;
      text-align: center;
    }

    .content {
      flex: 1;
      width: 100%;

      .loginOptions {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;

        .radioInput {
          display: flex;
          align-items: center;
          gap: 8px;

          > label {
            font-size: 14px;
            color: #262626;
          }

          input {
            width: 18px;
            height: 18px;
            outline: none;
            accent-color: $fast-proposal;
          }

          &.resaleFast {
            input {
              accent-color: $tertiary-color;
            }
          }
        }
      }

      .input {
        input {
          border-radius: 4px;
          height: 40px;
        }
      }

      .inputLabel {
        > p {
          font-size: 12px;
          color: #6b6b6b;
        }
      }
      .resendCodeBtn {
        all: unset;
        cursor: pointer;
        margin-top: 24px;
        text-decoration: underline;
        font-size: 12px;
        color: #6b6b6b;
      }
    }

    .footer {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 52px;

      button[name='voltar'] {
        all: unset;
        cursor: pointer;
        text-decoration: underline;
        font-size: 14px;
      }

      button[name='login'] {
        margin-left: auto;
        min-width: 160px;
        cursor: pointer;
      }
    }
  }
}
