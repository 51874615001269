.modalContainer {
  max-width: 468px;
  padding: 36px 24px;
  border-radius: 8px;

  .title {
    font-weight: 700;
    font-size: 24px;
    color: #013542;
    text-align: center;
    margin-bottom: 36px;
  }

  .input {
    input {
      border-radius: 4px;
      height: 40px;
    }

    &.disabled {
      input {
        color: #6b6b6b;
        border: 1px solid #dadada;
        background: #f5f5f5;
      }
    }
  }

  .inputLabel {
    > p {
      font-size: 12px;
      color: #6b6b6b;
    }
  }

  .selectorForm {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 20px;

    .radioInput {
      display: flex;
      align-items: center;
      gap: 8px;

      > input {
        width: 18px;
        height: 18px;
        accent-color: #199b45;
      }

      &.radioRed {
        > input {
          accent-color: #fb4e5f;
        }
      }
    }
  }

  .modalFooter {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 39px;

    button {
      &:first-child {
        all: unset;
        cursor: pointer;
        text-decoration: underline;
      }

      &:last-child {
        min-width: 160px;
      }
    }
  }

  .resendBtn {
    all: unset;
    cursor: pointer;
    text-decoration: underline;
    margin-top: 24px;
    color: #6b6b6b;
    font-size: 12px;
  }
}
