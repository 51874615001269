@import '../../../assets/scss/variables.scss';
@import '../../../assets/scss/mixins.scss';

.modalContainer {
  max-width: 760px;
  border-radius: 8px;
  padding: 40px 39px;
  display: flex;
  flex-direction: column;

  &.resaleFast {
    padding: 0px;
    border: none;
    box-sizing: border-box;
    box-shadow: none;

    @include screen(max-sm) {
      [class*='clear'] {
        top: 10px;
      }
    }
  }

  .resaleFastBody {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 40px;
    background-image: url('../../../assets/svgs/fastProposal/resaleFastModal/bg.svg');
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;

    > section {
      margin-inline: 0px;
      display: flex;
      align-items: center;
      gap: 32px;
      padding-top: 52px;
      padding-inline: 32px;

      h1 {
        color: #7896ff;
        font-weight: 700;
        font-size: 28px;
      }

      svg {
        width: 200px;
      }

      @include screen(max-sm) {
        gap: 16px;
        padding-inline: 24px;

        h1 {
          font-size: 24px;
        }

        svg {
          width: 130px;
        }
      }
    }

    > div {
      display: flex;
      flex-direction: column;
      padding-inline: 32px;
      padding-bottom: 148px;

      p {
        display: flex;
        align-items: center;
        font-size: 20px;
        gap: 16px;
        font-size: 18px;

        svg {
          width: 80px;
          min-width: 80px;
        }

        b {
          font-weight: 600;
          color: $blue1;
        }
      }

      @include screen(max-sm) {
        padding-inline: 24px;

        p {
          font-size: 16px;
          svg {
            width: 60px;
            min-width: 60px;
          }
        }
      }
    }

    > svg {
      &:last-child {
        align-self: flex-end;
      }
    }
  }

  @include screen(xs) {
    padding: 40px 24px;
  }

  .top {
    display: flex;
    align-items: center;
    gap: 26px;

    .logo {
      width: 80px;
      height: 80px;
    }

    .title {
      font-size: 24px;
      font-weight: 700;
      color: #199b45;
      margin-bottom: 12px;
    }
  }

  .label {
    font-weight: 700;
    color: #199b45;
    margin-block: 24px 12px;
  }

  .lines {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .line {
      display: flex;
      align-items: center;
      gap: 16px;

      .icon {
        width: 64px;
        height: 64px;
        min-width: 64px;
        background-color: #f8f8f8;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;

        > svg {
          width: 32px;
          height: 32px;
        }
      }
    }
  }

  .asterisk {
    font-size: 12px;
    margin-top: 24px;
  }
}
